import React from "react";
import "../custom.css";
import ColourSlideButton from "./ColourSlideButton";

import CatLion from "../images/catlion-large.jpg";
import Typewriter from "../images/typewriter-large.jpg";
import Chess from "../images/chess-large.jpg";

const images = [CatLion, Typewriter, Chess];

const TransformationPanel = (props) => {
  return (
    <section id={props.id}>
      <div className="row">
        <div className="col-12 transformation-text-container">
          <div>{props.text}</div>

          <ColourSlideButton
            linkTo="/get-in-touch"
            text="Get In Touch"
            width="170px"
            backgroundColor="rgba(232,78,14,1)"
            unlitTextColor="rgba(200,211,217,1)"
            litTextColor="rgba(0,32,96,1)"
          />

          <div className="transformation-image-overlay">
            <img
              src={images[props.imageIndex]}
              alt={props.title}
              className="transformation-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default TransformationPanel;
