import { React } from "react";
import ColourSlideButton from "./ColourSlideButton";

import Jigsaw from "../images/jigsaw.jpg";
import Tiles from "../images/tiles.jpg";
import Meeting from "../images/meeting.jpg";
import SmartHome from "../images/smarthome.jpg";

const images = [Jigsaw, Tiles, Meeting, SmartHome];

export const HomeFunctionDetail = (props) => {
  return (
    <div className="row is-flex no-gutters text-image-border mt-5">
      <div className={`${props.textClasses} text-image-block-content`}>
        <h2 className="display-4 m-0 pb-4 title">{props.title}</h2>
        <p>{props.text}</p>
        <ColourSlideButton
          linkTo="/get-in-touch"
          width="170px"
          text="Get In Touch"
          backgroundColor="#ff0000"
          unlitTextColor="#fff"
          litTextColor="#fff"
        />
      </div>

      <div className={props.imageClasses}>
        <img
          src={images[props.imageIndex]}
          className="text-image-block-image"
          alt=""
        />
      </div>
    </div>
  );
};

export default HomeFunctionDetail;
