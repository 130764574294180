import { Fragment, React } from "react";

import TransformationPanel from "./TransformationPanel";
import CreateHead from "./CreateHead";
import "../custom.css";

import Evolution from "../images/evolution.png";

function WhatWeDo() {
  const headItems = {
    title: "What We Do | The Orthello Partnership",
    description:
      "We provide a range of services in the Energy, Telecoms and Financial Services Sectors",
    image: { name: "og-evolution-1200x600.jpg", width: "1200", height: "600" },
  };

  return (
    <Fragment>
      <CreateHead headItems={headItems} />
      <main>
        <section className="banner-image">
          <div className="banner-image-container">
            <div className="banner-image-background-image banner-image-what-we-do">
              <img className="banner-image-img" src={Evolution} alt="" />
            </div>
            <div className="banner-image-content">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-10 col-md-8 text-center">
                    <h1 className="display-3 color-white">
                      Helping Our Customers Adapt To New Challenges Is At The
                      Heart of Everything We Do
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container py-4 py-md-7">
          <TransformationPanel
            imageIndex="0"
            id="business-transformation"
            text={
              <Fragment>
                <h2 className="m-0 pb-2">Business Transformation</h2>
                <p>
                  The pace of market change and the speed of technical
                  advancement requires organisations to constantly transform to
                  stay ahead of the competition, it is no longer good enough to
                  simply evolve. This creates a significant challenge as most
                  organisations are setup to operate business-as-usual, and
                  whilst internal capability often exists to deliver
                  evolutionary change and continuous improvement,
                  transformational change is different and it requires dedicated
                  professionals.
                </p>
                <p>
                  At The Orthello Partnership we specialise in the delivery and
                  acceleration of Business Transformation Programmes, whether
                  that be providing experienced Leadership Teams to drive the
                  internal teams and manage the third-party system integrators
                  delivering the change, providing rescue and acceleration
                  specialists that are skilled at injecting pace and refocussing
                  teams on the things that matter, or providing senior
                  Independent Assurance Partners to guide and coach your teams
                  through the transformation whilst enforcing strong governance.
                </p>
                <p style={{ color: "rgba(232,78,16,1)" }}>
                  <b>
                    Business Transformation requires specialist Business
                    Transformation professionals. It’s what we do.
                  </b>
                </p>
              </Fragment>
            }
          />
          <TransformationPanel
            imageIndex="1"
            id="technology-transformation"
            text={
              <Fragment>
                <h2 className="m-0 pb-2">Technology Transformation</h2>
                <p>
                  Organisations need to have the latest technology solutions to
                  keep up with the market and to support business
                  transformation, whether that be to offer cutting-edge products
                  and services, increase automation to improve the pace of
                  delivery, or to create organisational efficiencies and reduce
                  costs. The challenge for most organisations is the legacy
                  technology that has been stitched together over years of rapid
                  business growth and unprecedented technology advancement, and
                  there comes a point where the legacy technology is no longer
                  able to keep up, is expensive to support and maintain, creates
                  business and process complexity, and presents an unacceptable
                  security and data security risk.
                </p>
                <p>
                  We understand the challenge and are able to support the
                  creation of a robust technology strategy and roadmap, and
                  support the transformation of the complex legacy platforms
                  into modern scalable solutions, with systems simplification
                  being a critical success factor.
                </p>
                <p style={{ color: "rgba(232,78,16,1)" }}>
                  <b>
                    Technology Transformation is highly complex and experience
                    is key. We have that experience.
                  </b>
                </p>
              </Fragment>
            }
          />

          <TransformationPanel
            imageIndex="2"
            id="workforce-transformation"
            text={
              <Fragment>
                <h2 className="m-0 pb-2">Workforce Transformation</h2>
                <p>
                  Your people are your greatest asset, and your biggest threat.
                  Organisations are changing so fast that it’s becoming really
                  hard for people to keep up, especially as most have a natural
                  tendency to resist change. And it’s not just organisational
                  change that presents a challenge, society is now moving at
                  such a pace that organisations need to constantly adapt to
                  meet the expectations of, and capitalise on their
                  increasingly-diverse workforce.
                </p>
                <p>
                  We have helped organisations transform their workforce,
                  whether that be through the assessment of current capability,
                  the introduction of advanced performance management,
                  supporting intelligent recruitment based on the development of
                  a cultural strategy, restructuring to increase efficiency and
                  create professional centres of excellence, or simply through
                  coaching and mentoring to support your people through
                  difficult periods of change. Where appropriate we will provide
                  resource augmentation to support a transition during the
                  transformation, either directly or through one of our
                  strategic partners.
                </p>
                <p style={{ color: "rgba(232,78,16,1)" }}>
                  <b>
                    Environment shapes behaviour. We create the right
                    environment.
                  </b>
                </p>
              </Fragment>
            }
          />
        </div>
      </main>
    </Fragment>
  );
}

export default WhatWeDo;
