import React from "react";
import { Helmet } from "react-helmet";
import { useGoogleAnalytics } from "./Analytics";

const CreateHead = (props) => {
  useGoogleAnalytics();

  return (
    <Helmet>
      <title>{props.headItems.title}</title>

      <meta name="title" content={props.headItems.title} />
      <meta name="description" content={props.headItems.description} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={props.headItems.title} />
      <meta
        property="og:image"
        content={`/images/${props.headItems.image.name}`}
      />
      <meta property="og:image:width" content={props.headItems.image.width} />
      <meta property="og:image:height" content={props.headItems.image.height} />
      <meta property="og:description" content={props.headItems.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Orthello" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:locale:alternate" content="en_GB" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="mask-icon"
        href="/images/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#da532c" />

      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="e248ea78-8059-449a-b5af-960fd5bc5455"
        data-blockingmode="auto"
        type="text/javascript"
      ></script>
    </Helmet>
  );
};

export default CreateHead;
