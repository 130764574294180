import React from "react";
import BannerImage from "../images/woman.jpg";
import "../custom.css";

function HomeBanner() {
  return (
    <div className="banner-image-container banner-image-container-large banner-image-container-with-links">
      <div className="banner-image-background-image">
        <img
          src={BannerImage}
          className="banner-image-background-image"
          alt=""
        />
      </div>
      <div className="banner-image-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-5 col-lg-6 offset-lg-5 text-center">
              <h1 className="display-1-large">
                An Innovative Consultancy with a Fresh Approach
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
