import React from "react";
import "../custom.css";
import forest from "../images/sequoia.png";

const News = (props) => {
  return (
    <section className="homepage-section">
      <div className="container banner-image-btn-wrapper">
        <div className="row no-gutters">
          <div className="mb-5 mt-5 homepage-title">
            <div className="centre-align">
              <h2 className="py-1 homepage-title-text">News</h2>
            </div>
          </div>
        </div>
        <div className="row is-flex no-gutters text-image-border">
          <div className="col-lg-6}">
            <img
              src={forest}
              style={{ width: "400px" }}
              className="text-image-block-image"
              alt=""
            />
          </div>
          <div className="col-lg-6 text-image-block-content">
            <h4 className="ml-4 pb-4">
              <a className="homepage-headline" href="/newscarbonneutral">
                Orthello invests in Making Itself Carbon Neutral
              </a>
            </h4>
            <p className="ml-4">
              Orthello has just invested in making all its partners, staff and
              associates carbon neutral for life. Find out about the Orthello
              Forest{" "}
              <a className="homepage-headline" href="/newscarbonneutral">
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
