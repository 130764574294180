import { useState, useRef, React } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { animated, useSpring, useChain } from "react-spring";
import "../custom.css";

import CatLion from "../images/catlion.jpg";
import Typewriter from "../images/typewriter.jpg";
import Chess from "../images/chess.jpg";

const popUpImages = [CatLion, Typewriter, Chess];

export const HomeCapabilityOptionButton = (props) => {
  const [isPoppedUp, setPopUp] = useState(false);
  const [isButtonLit, setButtonLight] = useState(false);

  const colorFillRef = useRef();
  const popUpRef = useRef();

  const colorFill = useSpring({
    from: { height: "5px", backgroundColor: props.color },
    to: {
      height: isPoppedUp ? "90px" : "5px",
      backgroundColor: props.color,
    },
    ref: colorFillRef,
  });

  const buttonTextColor = useSpring({
    color: isButtonLit ? props.highlightTextColor : "rgba(0,0,0,1)",
    zIndex: "10",
  });

  const revealTextColor = useSpring({
    color: isPoppedUp ? props.highlightTextColor : "rgba(0,0,0,1)",
  });

  const popUp = useSpring({
    from: { height: "0px" },
    to: {
      height: isPoppedUp ? "500px" : "0px",
    },
    ref: popUpRef,
  });

  const buttonLight = useSpring({
    backgroundColor: props.color,
    width: isButtonLit ? "150px" : "10px",
  });

  useChain(isPoppedUp ? [colorFillRef, popUpRef] : [popUpRef, colorFillRef]);

  return (
    <div className={props.classes} style={{ padding: "0" }}>
      <Link
        to={props.linkTo}
        className="d-lg-none capability-reveal"
        style={{ borderColor: props.color }}
      >
        <animated.div
          style={revealTextColor}
          className="capability-reveal-container"
        >
          {props.title}
        </animated.div>
      </Link>

      <div className="d-none d-lg-block capability-reveal">
        <animated.div
          className="capability-reveal-color-reveal"
          style={colorFill}
        ></animated.div>
        <animated.div
          onMouseEnter={() => setPopUp(true)}
          onMouseLeave={() => setPopUp(false)}
          style={revealTextColor}
          className="capability-reveal-container"
        >
          {props.title}
        </animated.div>
        <animated.div
          onMouseEnter={() => setPopUp(true)}
          onMouseLeave={() => setPopUp(false)}
          style={popUp}
          className="capability-reveal-hover-content"
        >
          <img
            className="capability-popup-image"
            src={popUpImages[props.imageIndex]}
            alt={props.title}
          />
          <div className="px-3 py-3 capability-reveal-text-box">
            <p>{props.popUpText}</p>
            <Link
              to={props.linkTo}
              className="btn capability-reveal-btn"
              onMouseEnter={() => {
                setButtonLight(true);
              }}
              onMouseLeave={() => {
                setButtonLight(false);
              }}
            >
              <animated.span
                className="btn-block-color"
                style={buttonLight}
              ></animated.span>
              <animated.span className="btn-text" style={buttonTextColor}>
                More Info
              </animated.span>
            </Link>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default HomeCapabilityOptionButton;
