import { useState, React } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

const ColourSlideButton = (props) => {
  const [isButtonLit, setButtonLight] = useState(false);

  const buttonLight = useSpring({
    backgroundColor: props.backgroundColor,
    width: isButtonLit ? props.width : "10px",
  });

  const buttonTextColor = useSpring({
    color: isButtonLit ? props.litTextColor : props.unlitTextColor,
    zIndex: "10",
  });

  return (
    <Link
      to={props.linkTo}
      className="btn"
      style={{
        width: props.width,
        zIndex: "5",
        backgroundColor: "rgba(0,32,96,1)",
      }}
      onMouseEnter={() => setButtonLight(true)}
      onMouseLeave={() => setButtonLight(false)}
    >
      <animated.span
        className="btn-block-color"
        style={buttonLight}
      ></animated.span>
      <animated.span className="btn-text" style={buttonTextColor}>
        {props.text}
      </animated.span>
    </Link>
  );
};

export default ColourSlideButton;
