import React from "react";
import "../custom.css";

import DXC from "../images/clients/dxc.jpg";
import LandGyr from "../images/clients/landg.png";
import HL from "../images/clients/hl.jpg";
import Vodafone from "../images/clients/vodafone.png";
import Sainsburys from "../images/clients/sainsburys.jpg";
import TMobile from "../images/clients/tmobile.png";
import MBS from "../images/clients/mbs.jpg";
import Wyevale from "../images/clients/wyevale.jpg";
import SAS from "../images/clients/sas.png";
import NAA from "../images/clients/naa.jpg";
import Monsoon from "../images/clients/monsoon.png";
import HP from "../images/clients/hp.png";
import Homebase from "../images/clients/homebase.png";
import Nationwide from "../images/clients/nationwide.png";
import Meteor from "../images/clients/eir.png";
import Du from "../images/clients/du.jpg";
import DCC from "../images/clients/dcc.png";
import BetVictor from "../images/clients/betvictor.jpg";
import Barclays from "../images/clients/barclays.jpg";
import HSBC from "../images/clients/hsbc.png";
import LandG from "../images/clients/legal-and-general.jpg";
import OldMutualWealth from "../images/clients/old-mutual-wealth.jpg";
import Santander from "../images/clients/santander.jpg";
import StJamesPlace from "../images/clients/st-james-place.jpg";
import Fujitsu from "../images/clients/fujitsu.png";
import Eon from "../images/clients/eon.png";
import giffgaff from "../images/clients/giffgaff.png";
import NationalGrid from "../images/clients/national-grid.png";
import ThamesWater from "../images/clients/thames-water.png";
import Visa from "../images/clients/visa.png";
import SunLife from "../images/clients/sunlife.jpg";

const Clients = () => {
  return (
    <section className="logo-list my-4 my-sm-7">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <h2 className="pt-md-5 pt-1 display-1 color-primary title">
              Our founders have worked extensively with clients across multiple
              sectors.
            </h2>
          </div>
        </div>
        <div className="row pb-2 pb-md-4">
          <div className="col-sm-3">
            {" "}
            <h3 className="text-center">Telco</h3>
            <ul className="list-inline text-center">
              <li className="recent-client-logo">
                <img src={Vodafone} alt="Vodafone" />
              </li>
              <li className="client-logo">
                <img
                  style={{ width: "50%", height: "50%" }}
                  src={TMobile}
                  alt="T-Mobile"
                />
              </li>
              <li className="client-logo">
                <img src={MBS} alt="Mobile by Sainsburys" />
              </li>
              <li className="client-logo">
                <img src={Meteor} alt="Eir-Meteor" />
              </li>
              <li className="client-logo">
                <img src={Du} alt="Du" />
              </li>
              <li className="client-logo">
                <img src={Fujitsu} alt="Fujitsu" />
              </li>
              <li className="client-logo">
                <img src={giffgaff} alt="giffgaff" />
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h3 className="text-center">Utilities</h3>
            <ul className="list-inline text-center">
              <li className="recent-client-logo">
                <img src={DCC} alt="Data Communications Company" />
              </li>
              <li className="recent-client-logo">
                <img src={LandGyr} alt="Landis+Gyr" />
              </li>

              <li className="recent-client-logo">
                <img src={NationalGrid} alt="National Grid" />
              </li>
              <li className="recent-client-logo">
                <img src={ThamesWater} alt="Thames Water" />
              </li>
              <li className="recent-client-logo">
                <img src={Eon} alt="Eon" />
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-inline text-center">
              <h3 className="text-center">Financial Services</h3>
              <li className="recent-client-logo">
                <img src={HL} alt="Hargreaves Lansdown" />
              </li>
              <li className="client-logo">
                <img src={Nationwide} alt="Nationwide" />
              </li>
              <li className="client-logo">
                <img src={Barclays} alt="Barclays" />
              </li>
              <li className="client-logo">
                <img src={HSBC} alt="HSBC" />
              </li>
              <li className="client-logo">
                <img src={LandG} alt="Legal and General" />
              </li>
              <li className="client-logo">
                <img src={OldMutualWealth} alt="Old Mutual" />
              </li>
              <li className="client-logo">
                <img src={Santander} alt="Santander" />
              </li>
              <li className="client-logo">
                <img src={StJamesPlace} alt="St James Place" />
              </li>
              <li className="client-logo">
                <img src={SunLife} alt="Sun Life" />
              </li>{" "}
              <li className="client-logo">
                <img src={Visa} alt="Visa" />
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h3 className="text-center">Retail and Other</h3>

            <ul className="list-inline text-center">
              <li className="recent-client-logo">
                <img src={DXC} alt="Sainsburys" />
              </li>
              <li className="client-logo">
                <img src={Sainsburys} alt="Sainsburys" />
              </li>{" "}
              <li className="client-logo">
                <img src={Wyevale} alt="Wyevale" />
              </li>
              <li className="client-logo">
                <img src={SAS} alt="SAS" />
              </li>
              <li className="client-logo">
                <img src={NAA} alt="National Assessment Agency" />
              </li>
              <li className="client-logo">
                <img src={Monsoon} alt="Monsoon" />
              </li>
              <li className="client-logo">
                <img src={HP} alt="Hewlett Packard" />
              </li>{" "}
              <li className="client-logo">
                <img src={Homebase} alt="Homebase" />
              </li>{" "}
              <li className="client-logo">
                <img src={BetVictor} alt="Bet Victor" />
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 text-center pb-2">
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
