import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import MobileNav from "./MobileNav";
import Logo from "../images/logo.svg";
import { Container, Row } from "react-bootstrap";

import "../custom.css";

function Header() {
  return (
    <header className="header">
      <Container>
        <Row>
          <div className="col-6 col-md-4 col-lg-3">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <Nav />
          <MobileNav />
        </Row>
      </Container>
    </header>
  );
}

export default Header;
