import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-primary-content">
          <div className="col-lg-5">
            <p className="footer-contact-detail">
              <Link to="tel:0330 133 2201">0330 133 2201</Link>
            </p>
            <p className="footer-contact-detail">
              <Link to="mailto:info@orthello.co.uk">info@orthello.co.uk</Link>
            </p>
            <p className="footer-contact-detail">
              Peter House, Oxford Street, Manchester M1 5AN
            </p>
          </div>

          <div className="col-lg-7">
            <div className="row">
              <div className="col-12">
                <ul className="list-unstyled footer-nav-menu">
                  <li className="footer-nav-item">
                    <a
                      href="http://www.linkedin.com/company/66604775/"
                      className="footer-nav-link footer-nav-link-icon"
                    >
                      <svg
                        className="svg-inline-fa fa-linkedin fa-w-14"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="linkedin"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <ul className="list-unstyled footer-nav-menu">
                  <li className="footer-nav-item footer-nav-item-small">
                    <Link
                      to="/cookie-policy"
                      className="footer-nav-link footer-nav-link-small"
                    >
                      Cookie policy
                    </Link>
                  </li>
                  <li className="footer-nav-item footer-nav-item-small">
                    <Link
                      to="/privacy-policy"
                      className="footer-nav-link footer-nav-link-small"
                    >
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
