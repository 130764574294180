import { Fragment, React } from "react";

import CreateHead from "./CreateHead";
import "../custom.css";

import Forest from "../images/sequoia_plain.jpg";
import Vince from "../images/vince.jpg";

function NewsCarbonNeutral() {
  const headItems = {
    title: "News | The Orthello Partnership",
    description:
      "Orthello are proud to create the Orthello Forest, planting a tree for every associate",
    image: { name: "og-evolution-1200x600.jpg", width: "1200", height: "600" },
  };

  return (
    <Fragment>
      <CreateHead headItems={headItems} />
      <main>
        <section className="banner-image">
          <div className="news-image-container">
            <div className="banner-image-background-image banner-image-what-we-do">
              <img className="news-image" src={Forest} alt="" />
            </div>
          </div>
        </section>
        <div className="container py-4 py-md-7">
          <h4 className="my-4 py-2">
            ANNOUNCEMENT: Our Talent is now Carbon Neutral
          </h4>
          <p>
            As a young company born in an age of ever-increasing environmental
            concern, we are naturally conscious of our responsibility to
            contribute to the reduction of environmental pollution. Our Smart
            Centre of Excellence is one example of our commitment and we are
            delighted to be at the heart of some really exciting initiatives
            that will make a significant difference.
          </p>
          <p>
            Beyond our desire to be involved in exciting and innovative
            opportunities with like-minded organisations that will have a
            significant impact on the environment, we have a sense of duty much
            closer to home. Our core is our talent, and with that we feel
            passionately that we need to support our people in doing their bit
            for the future of the planet, a responsibility that goes further
            than simply reducing travel or supplying the latest most energy
            efficient equipment.
          </p>
          <p>
            <b>
              Our first commitment is to plant a Giant Sequoia for everybody
              that works for The Orthello Partnership. We are delighted to
              announce that we have today commissioned the planting of 16 Giant
              Sequoias in Abergavenny
            </b>
            , one for each member of our talented team that has contributed to a
            successful first year of trading. The average UK person’s lifetime
            CO2 footprint is 520 tonnes, a Giant Sequoia will absorb this and
            more, enabling those who work with The Orthello Partnership to live
            a carbon neutral existence.
          </p>
          <img className="vince-pic" src={Vince} alt="Vince Rawle" />
          <p>
            One of our Managing Partners, Vince Rawle had this to say…’This is a
            great initiative and it supports our absolute commitment to being a
            Carbon Neutral business. Whilst this is not a huge financial
            investment, as a young company there are many other ways we could
            have prioritised this investment to support our growth, but the
            environment is incredibly important to us and we wanted to set the
            tone for our future, and be true to our values. We do of course
            always need to do more, neutrality is just a foundation on which to
            build. From localised initiatives such as the planting of Giant
            Sequoias for our workforce, to the partnerships and ideas we’re
            developing within our Smart Centre of Excellence, Carbon Positive is
            our goal.’{" "}
          </p>
          <p>
            If you’d like to find out more about what we’re doing, please get in
            touch. For more information about the Giant Sequoia and the One Life
            One Tree Project, visit Sequoia Patrons — One Life One Tree.
          </p>
        </div>
      </main>
    </Fragment>
  );
}

export default NewsCarbonNeutral;
