import { useState, React } from "react";
import { useSpring, animated } from "react-spring";

const ColourSlideSubmitButton = (props) => {
  const [isButtonLit, setButtonLight] = useState(false);

  const buttonLight = useSpring({
    backgroundColor: !props.enabled
      ? "rgba(50,50,50,1)"
      : props.backgroundColor,
    width: isButtonLit && props.enabled ? props.width : "10px",
  });

  const buttonTextColor = useSpring({
    color: !props.enabled
      ? "rgba(50,50,50,1)"
      : isButtonLit
      ? props.litTextColor
      : props.unlitTextColor,
    zIndex: 10,
  });

  const buttonBackground = useSpring({
    backgroundColor: props.enabled ? "rgba(0,32,96,1)" : "rgba(200,200,200,1)",
    width: props.width,
    zIndex: "5",
  });

  return (
    <animated.button
      className="btn"
      disabled={!props.enabled}
      onSubmit={props.submit}
      style={buttonBackground}
      onMouseEnter={() => setButtonLight(true)}
      onMouseLeave={() => setButtonLight(false)}
    >
      <animated.span
        className="btn-block-color"
        style={buttonLight}
      ></animated.span>
      <animated.span className="btn-text" style={buttonTextColor}>
        {props.text}
      </animated.span>
    </animated.button>
  );
};

export default ColourSlideSubmitButton;
