import React from "react";
import { HomeCapabilityOptionButton as OptionButton } from "./HomeCapabilityOptionButton";
import "../custom.css";
import Icon from "../images/icon.svg";

const HomeCapabilityMenu = (props) => {
  return (
    <div className="container banner-image-btn-wrapper">
      <div className="row no-gutters">
        <OptionButton
          title="Business Transformation"
          id="bus-trans"
          classes="col-lg-3"
          linkTo="/what-we-do#business-transformation"
          color="rgba(232,78,14,1)"
          popUpText="Business Transformation Programmes typically experience avoidable delays and costly overspend, with too much focus on solutions instead of outcomes. Our battle-scared practitioners can help you get it right."
          imageIndex="0"
          highlightTextColor="rgba(50,50,50,1)"
        />

        <div className="d-none d-lg-block col-lg-1">
          <div className="orthello-spacer orthello-spacer-left">
            <img className="orthello-icon-image" src={Icon} alt="" />
          </div>
        </div>

        <div className="col-lg-4">
          <OptionButton
            title="Technology Transformation"
            id="tech-trans"
            linkTo="/what-we-do#technology-transformation"
            color="rgba(200,211,217,1)"
            popUpText="Technology Transformation provides significant opportunity. Our expert team can help you transform your legacy systems, manage complex systems integration, and deploy cutting edge solutions."
            imageIndex="1"
            highlightTextColor="rgba(50,50,50,1)"
          />
          <div className="orthello-services-title">
            <div className="orthello-slogan">Embracing Change</div>
          </div>
        </div>

        {/* 2nd Orthello Icon */}
        <div className="d-none d-lg-block col-lg-1">
          <div className="orthello-spacer orthello-spacer-right">
            <img
              className="orthello-icon-image"
              src={Icon}
              alt="Orthello Icon"
            />
          </div>
        </div>

        <OptionButton
          title="Workforce Transformation"
          id="work-trans"
          classes="col-lg-3"
          linkTo="/what-we-do#workforce-transformation"
          color="rgba(0,32,96,1)"
          popUpText="Your workforce is the lifeblood of your organisation and needs to continually evolve and adapt . We can help you create the right structure, environment, and culture to optimise this critical asset."
          imageIndex="2"
          highlightTextColor="rgba(127,127,127,1)"
        />
      </div>
    </div>
  );
};

export default HomeCapabilityMenu;
