import { React, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import GetInTouch from "./components/GetInTouch";
import WhoWeAre from "./components/WhoWeAre";
import WhatWeDo from "./components/WhatWeDo";
import CookiePolicy from "./components/CookiePolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CaseStudyDataCentre from "./components/CaseStudyDataCentre";
import NewsCarbonNeutral from "./components/NewsCarbonNeutral";

import "./custom.css";

function App() {
  return (
    <Fragment>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/get-in-touch" component={GetInTouch} />
          <Route path="/what-we-do" component={WhatWeDo} />
          <Route path="/who-we-are" component={WhoWeAre} />
          <Route path="/cookie-policy" component={CookiePolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/casestudydatacentre" component={CaseStudyDataCentre} />
          <Route path="/newscarbonneutral" component={NewsCarbonNeutral} />
        </Switch>
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
