import React from "react";
import ScriptTag from "react-script-tag";
import CreateHead from "./CreateHead";

const CookiePolicy = () => {
  const headItems = {
    title:
      "An Innovative Consultancy with a Fresh Approach | The Orthello Partnership",
    description:
      "Providing Business, Technology and Workforce Transformation Services",
    image: { name: "og-woman-1200x665.jpg", width: "1200", height: "665" },
  };

  return (
    <main>
      <CreateHead headItems={headItems} />

      <section>
        <div className="policy-spacer"></div>
        <div className="container">
          <div className="policy-text">
            <h2>The Orthello Partnership Cookie Policy</h2>
            <div
              style={{
                width: "100%",
              }}
            >
              <ScriptTag
                id="CookieDeclaration"
                src="https://consent.cookiebot.com/e248ea78-8059-449a-b5af-960fd5bc5455/cd.js"
                type="text/javascript"
                async
              ></ScriptTag>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CookiePolicy;
