import { Fragment, React } from "react";
import CreateHead from "./CreateHead";
import "../custom.css";

import WorkStyle from "./WorkStyle";

import Hiking from "../images/hiking.jpg";

const headItems = {
  title: "Who We Are | The Orthello Partnership",
  description: "What drives us and how we work",
  image: { name: "og-hiking-1200x798.jpg", width: "1200", height: "798" },
};

function WhoWeAre() {
  return (
    <main>
      <CreateHead headItems={headItems} />

      <section className="banner-image">
        <div className="banner-image-container">
          <div className="banner-image-background-image banner-image-who-we-are">
            <img
              className="banner-image-background-image"
              src={Hiking}
              alt=""
            />
          </div>
          <div className="banner-image-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 col-md-8 text-center">
                  <h1 className="display-3 color-white">
                    If one Partner is losing their shirt, whilst the other is
                    counting their money, it is not a true Partnership.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="centre-headline">
        <div className="grey-bg py-4 py-sm-1">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="p-5">About The Orthello Partnership</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center custom-background-row">
              <div className="col-lg-10">
                <div className="col-12 pb-5">
                  <p>
                    The Orthello Partnership was created in 2020 by our Managing
                    Partners who between them have spent over 60 years leading
                    and supporting large scale complex change programmes and
                    transforming organisations. All of our highly skilled
                    professional practitioners have extensive experience across
                    multiple sectors and understand the importance of context
                    and the environment in which they are operating.
                  </p>
                  <p>
                    At The Orthello Partnership we are obsessive about outcomes
                    and relentless in our pursuit of our goals, and the goals of
                    our clients. Our business is built on integrity and doing
                    the right thing, creating long-term partnerships with our
                    clients and directly linking our success to your success.
                  </p>
                  <p>
                    The basic principle is very simple, if we help you to
                    succeed, we will be successful.{" "}
                    <b>Your future is our future…</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-3 my-lg-5">
        <div className="container">
          <WorkStyle
            textClasses="col-lg-5 order-1 order-lg-2 px-1 px-lg-3"
            imageClasses="col-lg-7 order-2 order-lg-1 text-image-block-col"
            title="Our Values"
            imageIndex="0"
            text={[
              <Fragment>
                <p>
                  Our Managing Partners have enjoyed success through being
                  flexible and always doing the right thing for the right
                  reason, never working to rule and never allowing personal gain
                  to blur judgement. This is in the DNA of The Orthello
                  Partnership.
                </p>
                <ul class="small orthello-bullet-list">
                  <li>
                    <b>We believe</b> that successful relationships must be
                    based on trust and mutual respect
                  </li>
                  <li>
                    <b>We engage</b> with everyone in good faith and value
                    integrity above all else
                  </li>
                  <li>
                    <b>We treat your business and your people</b> as if they
                    were our own, this really matters to us
                  </li>
                  <li>
                    <b>We are independent</b> and our actions and
                    recommendations always reflect that
                  </li>
                  <li>
                    <b>
                      We uphold the highest standards of quality and
                      professionalism
                    </b>{" "}
                    across all roles and levels
                  </li>
                  <li>
                    <b>We recognise and promote</b> the importance and power of
                    diversity
                  </li>
                </ul>
              </Fragment>,
            ]}
          />
          <WorkStyle
            s
            textClasses="col-lg-5 text-image-block-col"
            imageClasses="col-lg-7 text-image-block-col"
            title="How we partner"
            imageIndex="1"
            text={
              <Fragment>
                <p>
                  Our entire business model is based on working in partnership
                  with our clients, and our unique commercial engagement model
                  ensures absolute commitment to this. We have to make you
                  successful, otherwise our business fails.
                </p>
                <ul class="small orthello-bullet-list">
                  <li>
                    <b>We take the time</b> to understand your business, and
                    your challenges, even when we’re not actively working with
                    you.
                  </li>
                  <li>
                    <b>We consider</b> your environment, your people, and your
                    culture so that our recommendations are right for you.
                  </li>
                  <li>
                    <b>We carefully select</b> practitioners that are well
                    suited to your organisation and the team they will be
                    working with.
                  </li>
                  <li>
                    <b>We provide open and transparent communication</b>{" "}
                    throughout every engagement and we don’t hide bad news
                  </li>
                  <li>
                    <b>We work flexibly</b> with you and accept that things can
                    change throughout an assignment
                  </li>
                </ul>
              </Fragment>
            }
          />
          <WorkStyle
            textClasses="col-lg-6 order-1 order-lg-2 px-lg-3 text-image-block-col"
            imageClasses="col-lg-6 order-2 order-lg-1 text-image-block-col"
            title="Why Us?"
            imageIndex="2"
            text={
              <Fragment>
                <ul className="small orthello-bullet-list">
                  <li>
                    <b>We are really good at what we do</b> and our commercial
                    model is such that we will only take on assignments that we
                    firmly believe can be successful. If we agree to work with
                    you, it’s because we know we can really work with you.
                  </li>
                  <li>
                    <b>We are an independently owned and financed business</b>,
                    making us easy to engage with and allowing us to be creative
                    and flexible to ensure we construct any deal to your exact
                    requirements.
                  </li>
                  <li>
                    <b>We are a new business just setting out</b>, so we simply
                    cannot afford to allow any assignment to fail; we will do
                    whatever it takes to make every engagement a success
                  </li>
                  <li>
                    <b>
                      We have a workforce that have learnt their trade on the
                      front-line
                    </b>
                    , not in the classroom; we don’t bring text books to work
                    and we don’t believe that one-size fits all. Our
                    practitioners are professionals.
                  </li>
                  <li>
                    <b>We have a workforce with practical awareness</b> from
                    living and breathing the experience rather than simply
                    applying theory; our practitioners have held senior
                    accountability in their roles and recognise the real-world
                    challenges.
                  </li>
                </ul>
                <p>
                  <b>
                    A better question is ‘Why Not Us?’ We can’t think of a good
                    reason.
                  </b>
                </p>
              </Fragment>
            }
          />
        </div>
      </section>
    </main>
  );
}

export default WhoWeAre;
