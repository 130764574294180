import { React } from "react";
import "../custom.css";

import Signpost from "../images/signpost.jpg";
import Hands from "../images/hands.jpg";
import WhyUs from "../images/why-us.jpg";

const WorkStyle = (props) => {
  const images = [Signpost, Hands, WhyUs];

  return (
    <div
      className="row is-flex no-gutters text-image-border"
      style={{ borderColor: "rgba(0,32,96,1)" }}
    >
      <div className={props.textClasses}>
        <div className="text-image-block-content">
          <h2 className="m-0 pb-2">{props.title}</h2>
          {props.text}
        </div>
      </div>
      <div className={props.imageClasses}>
        <div className="text-image-block-image">
          <img
            className="text-image-block-image"
            src={images[props.imageIndex]}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WorkStyle;
