import React, { Fragment } from "react";
import { HomeFunctionDetail as Detail } from "./HomeFunctionDetail";
import "../custom.css";

const HomeFunctions = () => {
  return (
    <section className="my-1 my-md-3 my-lg-5">
      <div className="container">
        <Detail
          imageIndex="0"
          textClasses="px-4 col-lg-4 order-lg-2"
          title="Delivery Leadership and Support"
          text={
            <Fragment>
              Successful delivery of change requires all parts of the jigsaw to
              be right; a realistic plan, a robust business case, exceptional
              leadership, a competent and motivated team, appropriate
              governance, aligned methodology, and the right culture and
              environment. The list could go on. Our experienced practitioners
              can take ownership of, or support you with, any element of the
              delivery ecosystem to ensure your change programmes deliver
              successfully.
            </Fragment>
          }
          imageClasses="col-lg-8 order-lg-1"
        />

        <Detail
          imageIndex="1"
          textClasses="px-4 col-lg-4"
          title="Resource Augmentation"
          text="We have an extensive network of skilled practitioners to augment
          your teams, covering all disciplines across the delivery
          lifecycle and hierarchy, from project administrators through to
          interim executives leading your change. In addition to providing
          practitioners with experience across all types of change and
          multiple industries, we provide subject matter expertise in
          Technology, Smart Energy, Telecommunications, Financial
          Services, Utilities, Security, and Human Resources."
          imageClasses="col-lg-8 text-image-block-col"
        />

        <Detail
          imageIndex="2"
          textClasses="px-4 col-lg-4 order-2"
          title={
            <Fragment>
              Governance Assist (GAP)<sup>TM</sup>
            </Fragment>
          }
          text={
            <Fragment>
              GAP<sup>TM</sup> provides you with exclusive access to a suite of
              products and services delivered by our Independent Assurance
              Professionals (IAPs). We will work closely with you to select the
              most appropriate member of our IAP panel to support you in
              assuring the success of your programme(s). All of our IAPs are
              C-Level Leaders with extensive experience of sponsoring and
              delivering large-scale complex change programmes.
            </Fragment>
          }
          imageClasses="col-lg-8 order-1"
        />

        <Detail
          imageIndex="3"
          textClasses="px-4 col-lg-6"
          title="Smart Centre of Excellence"
          text="Smart technology is changing almost all aspects of our lives
        and is undoubtedly the future. We have extensive experience of
        delivering smart technologies across utilities and
        telecommunications and have a team of subject matter experts
        ready to help you to ‘be smart’. From connectivity to data
        analytics, our Centre of Excellence can support you in any
        smart transformation, whether you are looking for hands-on
        leadership of change, or expert advice and guidance."
          imageClasses="col-lg-6"
        />
      </div>
    </section>
  );
};

export default HomeFunctions;
