import React from "react";
import CreateHead from "./CreateHead";
import HomeBanner from "./HomeBanner";
import HomeCapabilityMenu from "./HomeCapabiltyMenu";
import HomeFunctions from "./HomeFunctions";
import Clients from "./Clients";
import News from "./News";
import CaseStudyList from "./CaseStudyList";

const Home = () => {
  const headItems = {
    title:
      "An Innovative Consultancy with a Fresh Approach | The Orthello Partnership",
    description:
      "Providing Business, Technology and Workforce Transformation Services",
    image: { name: "og-woman-1200x665.jpg", width: "1200", height: "665" },
  };

  return (
    <main>
      <CreateHead headItems={headItems} />

      <section className="banner-image">
        <HomeBanner />
        <HomeCapabilityMenu />
      </section>
      <HomeFunctions />
      <News />
      <CaseStudyList />
      <Clients />
    </main>
  );
};

export default Home;
