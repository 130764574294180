import React from "react";
import Phone from "../images/mobile-phone.jpg";
import { Container, Row } from "react-bootstrap";

const CaseStudy = () => {
  return (
    <main>
      <section className="banner-image">
        <div className="banner-image-container">
          <div className="banner-image-background-image banner-image-what-we-do">
            <img className="banner-image-img" src={Phone} alt="" />
          </div>
          <div className="banner-image-content">
            <Container>
              <div className="row justify-content-center">
                <div className="col-xs-10 col-md-8 col-md-push-2 text-center">
                  <h1 className="display-3 display-3-shadow ">
                    When circumstances change, we can adapt.
                  </h1>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            <h1 className="case-study-heading">Problem</h1>
          </Row>
          <Row>
            <p>
              When a successful Virtual Telecoms Operator had planned to move
              the data centre hosting their website portal and customer care
              system to a new provider, they set an aggressive six month plan to
              deliver the migration and asked our managing partner to lead the
              project. What seemed like an initial aggressive target however,
              was to be thrown upside down on the first day of the engagement
              when the existing data centre provider went into administration.
              At this point nobody knew how long the infrastructure would remain
              operational, or indeed, how the migration would be conducted. The
              portal was central for more than a million subscribers to access
              their services and central to the survival of the business. The
              six month plan needed to be accelerated, and our Managing Partner
              was asked how quickly the migration could take place.
            </p>
          </Row>
          <Row>
            <h1 className="case-study-heading">The Solution</h1>
          </Row>
          <Row>
            <p>
              Having realised the gravity of the situation, the initial two days
              was turned into a rapid situation assessment to understand the
              priorities and criticality of every part of the service, to
              understand the options available and create a series of scenarios.
              Each scenario was risk assessed, and whilst security was never
              compromised, risks were assessed for each service against
              availability and speed of migration. As there was a very real risk
              the service provider would switch the lights off, getting the new
              service up and running quickly was the key focus. Working with the
              Operations and Development teams, a primary and secondary plan was
              launched which used a lot of the ideas from the team in how to
              accelerate the plan. One such acceleration was to send operations
              staff with encrypted hard drives into the data centre instead of
              downloading data over network links from the company’s office as
              had been the original plan, saving two days. Having iterated the
              plan a number of times, the timeline was reduced from a six month
              plan to a six week plan and the teams set to work building the new
              data centre in preparation for the migration.
            </p>
          </Row>
          <Row>
            <h1 className="case-study-heading">The Result</h1>
          </Row>
          <Row>
            <p>
              The plan was operated in an agile way, with each day starting with
              a stand-up to assess the success of the work on the previous day,
              to review if there were any changes required, or whether
              alternative approaches or resources were required. A number of
              changes were made on the way, but the teams working on the project
              took these changes in their stride, and six weeks after the
              initial meeting, the cutover weekend successfully delivered a
              fully migrated website and customer care system. When external
              events mean that you must change plans and accelerate, this
              collaborative and agile way of working is great at delivering
              results in the lowest time possible. In this example we took some
              calculated risks to system availability, but in doing so cut over
              four months off the delivery time. The client commented that they
              would never have considered the alternative approaches we took,
              but was very glad that we brought them forward!
            </p>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default CaseStudy;
