import { React, useState } from "react";
import { useForm } from "react-hook-form";
import CreateHead from "./CreateHead";
import ColorSlideSubmitButton from "./ColourSlideSubmitButton";
import "../custom.css";

import Contact from "../images/contact.jpg";

const GetInTouch = () => {
  const headItems = {
    title: "Get In Touch | The Orthello Partnership",
    description:
      "Get in touch with The Orthello Partnership and see how we can help you",
    image: { name: "og-contact-1200x699.jpg", width: "1200", height: "699" },
  };

  const [feedbackMessage, setFeedbackMessage] = useState(""); //Holds the message to inform the user of status of sending e-mail
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true); //Holds the status of the Send button, which gets disabled after Send to avoid sending message twice

  const { register, handleSubmit, errors } = useForm(); //From react-hook-form - holds status of the contact form and creates validations

  const onSubmit = (data) => {
    setFeedbackMessage("Sending your message");

    fetch(
      "https://pfm0ltj6k6.execute-api.us-east-2.amazonaws.com/prod/contact-us",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then(function (response) {
      setSendButtonEnabled(false);
      setFeedbackMessage(
        "Your message has been sent successfully - thank you.  We will respond shortly."
      );

      return response.json();
    });
  };

  return (
    <main>
      <CreateHead headItems={headItems} />

      <section className="banner-image">
        <div className="banner-image-container">
          <div className="banner-image-background-image">
            <img
              className="banner-image-img"
              src={Contact}
              alt=""
              style={{ objectPosition: "50% 50%" }}
            />
          </div>
          <div className="banner-image-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-10 col-md-8  text-center">
                  <h1 className="display-3 color-white">Let's Talk!</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form py-4 pb-md-7">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-8">
              <div className="contact-form-form-wrap grey-bg">
                <h2 className="m-0 pb-2">Contact Form</h2>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">
                          Your name<span className="form-astrik">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          ref={register({
                            required: "Name is Required",
                          })}
                          className="form-control"
                          style={
                            errors.name
                              ? { borderColor: "red" }
                              : { borderColor: "#555" }
                          }
                        ></input>
                        <div className="error-container">
                          {errors.name && (
                            <p className="error">{errors.name.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">
                          Your email address
                          <span className="form-astrik">*</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="E-Mail"
                          style={
                            errors.email
                              ? { borderColor: "red" }
                              : { borderColor: "#555" }
                          }
                          ref={register({
                            required: "E-Mail is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Please enter a valid e-mail address",
                            },
                          })}
                        />

                        <div className="error-container">
                          {errors.email && (
                            <p className="error">{errors.email.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="phone">Your phone number</label>
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Phone"
                          style={
                            errors.phone
                              ? { borderColor: "red" }
                              : { borderColor: "#555" }
                          }
                          ref={register}
                        />

                        <div className="error-container">
                          {errors.phone && (
                            <p className="error">{errors.phone.message}</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>{feedbackMessage}</p>
                      </div>
                    </div>

                    <div className="col-xs-12 col-md-6">
                      <div className="form-group form-group--textarea">
                        <label htmlFor="message">Your message</label>
                        <textarea
                          name="message"
                          className="form-control"
                          rows="7"
                          placeholder="Your Message"
                          style={
                            errors.message
                              ? { borderColor: "red" }
                              : { borderColor: "#555" }
                          }
                          ref={register({ required: "Message is required" })}
                        ></textarea>{" "}
                        <div className="error-container">
                          {errors.message && (
                            <p className="error">{errors.message.message}</p>
                          )}
                        </div>
                      </div>

                      <ColorSlideSubmitButton
                        submit={handleSubmit(onSubmit)}
                        width="150px"
                        text="Send"
                        enabled={sendButtonEnabled}
                        backgroundColor="rgba(232,78,14,1)"
                        unlitTextColor="rgba(200,211,217,1)"
                        litTextColor="rgba(0,32,96,1)"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GetInTouch;
