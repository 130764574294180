import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const TRACKING_ID = "UA-180791680-1";

function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

/*
function sendEvent(payload) {
  ReactGA.event(payload);
}
*/

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export function useGoogleAnalytics() {
  const location = useLocation();
  console.log(location.pathname + " " + location.search);

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);
}
