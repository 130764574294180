import React from "react";
import MenuItems from "./MenuItems";

import "../custom.css";

function Nav() {
  return (
    <div className="d-none d-lg-block col-md-9">
      <nav className="d-none d-lg-block navigation">
        <MenuItems />
      </nav>
    </div>
  );
}

export default Nav;
