import { React, Fragment } from "react";
import { Link } from "react-router-dom";
import "../custom.css";

const MenuItems = (props) => {
  return (
    <Fragment>
      <ul className="list-unstyled nav-menu-wrap">
        <li className="nav-item">
          <Link to="/" className="nav-link" onClick={props.closeMenuCallback}>
            Home
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/who-we-are"
            className="nav-link"
            onClick={props.closeMenuCallback}
          >
            Who We Are
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/what-we-do"
            className="nav-link"
            onClick={props.closeMenuCallback}
          >
            What We Do
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/get-in-touch"
            className="nav-link"
            onClick={props.closeMenuCallback}
          >
            Get In Touch
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

export default MenuItems;
