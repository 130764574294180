import React from "react";
import "../custom.css";
import phone from "../images/mobile-phone.jpg";

const CaseStudyList = (props) => {
  return (
    <section className="homepage-section">
      <div className="container banner-image-btn-wrapper">
        <div className="row no-gutters">
          <div className="mb-5 mt-5 homepage-title">
            <div className="centre-align">
              <h2 className="homepage-title-text">Case Study</h2>
            </div>
          </div>
        </div>
        <div className="row is-flex no-gutters text-image-border">
          <div className="col-lg-6}">
            <img
              src={phone}
              style={{ width: "400px", height: "100%", marginBottom: "20px" }}
              className="text-image-block-image"
              alt=""
            />
          </div>
          <div className="col-lg-6 text-image-block-content">
            <h4 className="ml-4 pb-4">
              <a className="homepage-headline" href="/casestudydatacentre">
                How Adaptabilty Saved our Customers Business
              </a>
            </h4>
            <p className="ml-4">
              When circumstances dramatically changed shortly after an Orthello
              Managing Partner started work at a Telco putting its entire
              business at risk, rapid replanning, adaptability and teamwork were
              the recipe for a successful outcome. Read about it{" "}
              <a className="homepage-headline" href="/casestudydatacentre">
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyList;
