import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MenuItems from "./MenuItems";

function MobileNav() {
  const [showMenu, setShowMenu] = useState(false);

  const NavMenuSlide = useSpring({ width: showMenu ? "80%" : "0%" });
  const NavMenuSidebarSlide = useSpring({ width: showMenu ? "20%" : "0%" });

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className="d-lg-none col-6 col-md-8 col-lg-9">
      <nav>
        <span
          className="float-right"
          style={{ fontSize: "30pt", color: "rgba(232,78,14,1)" }}
        >
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
        </span>
        <animated.div className="mobile-menu" style={NavMenuSlide}>
          <Link to="/" className="nav-close-icon" onClick={closeMenu}>
            <span className="nav-close-icon-line"></span>
            <span className="nav-close-icon-line nav-close-icon-line-last"></span>
          </Link>

          <MenuItems closeMenuCallback={closeMenu} />
        </animated.div>
        <animated.div
          className="mobile-menu-sidebar"
          style={NavMenuSidebarSlide}
          onClick={() => setShowMenu(false)}
        ></animated.div>
      </nav>
    </div>
  );
}

export default MobileNav;
